import React from 'react';
import "./spinner.scss";

const Spinner = ({small}) => {
    return small
        ?
        <div className='d-flex position-relative' style={{height:30,width:30}}><div className="spinner__image_animate"></div></div>
        :
        <div className="spinner">
            <div className="spinner__image_animate"></div>
        </div>
    ;
};

export default Spinner;
